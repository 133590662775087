import {
  BrowserCacheLocation,
  Configuration,
  LogLevel,
} from '@azure/msal-browser';
import { environment } from '../environments/environment';

export const msalConfig: Configuration = {
  auth: {
    clientId: environment.msalConfig.auth.clientId,
    authority:
      'https://login.microsoftonline.com/9f5e8f3e-5a81-4130-8e84-1309e94b80d0',
    redirectUri: environment.msalConfig.auth.REPLYURL,
    postLogoutRedirectUri: environment.msalConfig.auth.REPLYURL,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
  },
  system: {
    loggerOptions: {
      loggerCallback(logLevel: LogLevel, message: string) {
        // console.log(message);
      },
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false,
    },
  },
};

export const loginRequest = {
  scopes: ['b9eed427-97c4-4e68-b269-b9c45586c8df/.default'],
};
