<ul class="layout-menu">
    <ng-container *ngFor="let item of model; let i = index;">
    <!-- *hasPermission="{onResource: 'USER', onActions: ['VIEW'], otherwise: 'HIDE'}" -->
        
        <li app-menuitem *ngIf="!item.separator" 
            [item]="item" [index]="i" [root]="true">
        </li>
        <li *ngIf="item.separator" class="menu-separator"></li>
    </ng-container>
    <!-- <li>
        <a href="https://www.primefaces.org/primeblocks-ng/#/">
            <img src="assets/layout/images/{{layoutService.config().colorScheme === 'light' ? 'banner-primeblocks' : 'banner-primeblocks-dark'}}.png" alt="Prime Blocks" class="w-full mt-3"/>
        </a>
    </li> -->
</ul>
