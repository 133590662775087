import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InputTextModule } from 'primeng/inputtext';
import { SidebarModule } from 'primeng/sidebar';
import { BadgeModule } from 'primeng/badge';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { RippleModule } from 'primeng/ripple';
// import { AppMenuitemComponent } from './app.menuitem.component';
import { RouterModule } from '@angular/router';
import { AppConfigModule } from './config/config.module';
import { CommonModule } from '@angular/common';
import { AppMenuitemComponent } from './app.menuitem.component';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    InputTextModule,
    SidebarModule,
    BadgeModule,
    RadioButtonModule,
    InputSwitchModule,
    RippleModule,
    RouterModule,
    AppConfigModule,
    AppMenuitemComponent,
  ],
})
export class AppMenuComponent implements OnInit {
  model: any[] = [];

  constructor(public layoutService: LayoutService) {}

  ngOnInit() {
    this.model = [
      {
        label: 'Home',
        items: [
          {
            label: 'Dashboard',
            icon: 'pi pi-fw pi-home',
            routerLink: ['/main/home/dashboard'],
          },
          {
            label: 'Annual Business Plan',
            icon: 'pi pi-address-book',
            routerLink: ['/main/home/annual-business-plan'],
          },
        ],
      },
      {
        label: 'Lead to Contract',
        items: [
          // { label: 'Leads', icon: 'pi pi-fw pi-id-card', routerLink: ['/main/lead-to-contract/leads'] },
          // { label: 'Proposals', icon: 'pi pi-fw pi-check-square', routerLink: ['/main/lead-to-contract/proposals'] },
          {
            label: 'Leads',
            icon: 'pi pi-fw pi-crown',
            routerLink: ['/main/lead-to-contract/leads'],
          },
          {
            label: 'Proposals',
            icon: 'pi pi-fw pi-file-edit',
            routerLink: ['/main/lead-to-contract/proposals'],
          },
          {
            label: 'Win-Loss',
            icon: 'pi pi-fw pi-bookmark',
            routerLink: ['/main/lead-to-contract/win-loss-list'],
          },
          {
            label: 'EMD Tracker',
            icon: 'pi pi-fw pi-wave-pulse',
            routerLink: ['/main/lead-to-contract/emd-tracker-list'],
          },
        ],
      },
      {
        label: 'contract to Plan',
        items: [
          {
            label: 'Projects (HOTO)',
            icon: 'pi pi-fw pi-file',
            routerLink: ['/main/hoto'],
            badge: 'NEW',
          },
        ],
      },
      {
        label: 'master',
        items: [
          {
            label: 'Clients',
            icon: 'pi pi-fw pi-users',
            routerLink: ['/main/master/clients'],
            badge: 'NEW',
          },
          {
            label: 'Cluster State',
            icon: 'pi pi-th-large',
            routerLink: ['/main/master/clusters'],
            badge: 'NEW',
          },
          {
            label: 'Users',
            icon: 'pi pi-user-plus',
            routerLink: ['/main/master/users'],
            badge: 'NEW',
          },
          {
            label: 'Roles',
            icon: 'pi pi-lock',
            routerLink: ['/main/master/roles'],
            badge: 'NEW',
          },
        ],
      },
    ];
  }
}
