import { Component, Inject, OnDestroy, OnInit, inject } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { ToastModule } from 'primeng/toast';
import { Location } from '@angular/common';
import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  InteractionStatus,
  InteractionType,
  PopupRequest,
  RedirectRequest,
  EventMessage,
  EventType
} from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
// import { MsalServiceV } from './shared/msal/msal.service';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { AuthService } from './auth/store/auth.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet,
    ToastModule,
    ConfirmDialogModule
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'Microsoft identity platform';
    loginDisplay = false;
    isIframe = false;

    private readonly _destroying$ = new Subject<void>();
    redirectHash: string | undefined;

    router = inject(Router);
    constructor(
      @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
      private authService: MsalService,
      private authServiceSys: AuthService,
      private msalBroadcastService: MsalBroadcastService,
      private location: Location
    ) { }

    ngOnInit(): void {
      // .split('#').pop()
      const hash = this.location.path(true).split('#').pop();
      if (hash) {
        this.redirectHash = `#${hash}`;
      }


        this.authService.handleRedirectObservable(this.redirectHash).subscribe({
          next: (result: AuthenticationResult) => {
            console.log(result);

            if (result) {

              // this.loadingPanelVisible = true;

              const name = result?.account?.name || '';
              localStorage.setItem('userName', name)!;
              // localStorage.setItem('userNameId', '01J8F3ZNV2PXGD0SPQJKMTT0M5')!;
              this.getToken(result);
              this.authService.instance.setActiveAccount(result.account);
              if(result?.accessToken){
                this.router.navigate(['/main/home/dashboard']);
              }
            }
          },
          error: (error) => {
            console.log(error);
          },
        });
    }
  fetchPermissionOfUser(userNameId: string) {
    this.authServiceSys.getPermissionOfUser(userNameId).subscribe((res:any)=> {
      console.log(res);
      if(res?.data !== null){
        localStorage.setItem('USER_PERMISSION', JSON.stringify(res?.data));
      }else {
        localStorage.setItem('USER_PERMISSION', '');
      }
      
    })
  }

    getToken(result: AuthenticationResult) {
      const data = {
        emailId: result.account.username,
        tokenType: 'Office365',
        token: result.accessToken,
        homeAccountId: result.account.homeAccountId,
        environment: result.account.environment
      };
      localStorage.setItem('MSALDATA', JSON.stringify(data));
      this.setToken(data);

      if(this.authServiceSys.getToken()){
        this.fetchPermissionOfUser('01J8F3ZNV2PXGD0SPQJKMTT0M5');
      }
    }
    setToken(data: any) {
      localStorage.setItem('O365', JSON.stringify(data));
    }


    setLoginDisplay() {
      this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    }

    checkAndSetActiveAccount() {
      /**
       * If no active account set but there are accounts signed in, sets first account to active account
       * To use active account set here, subscribe to inProgress$ first in your component
       * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
       */
      let activeAccount = this.authService.instance.getActiveAccount();

      if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
        let accounts = this.authService.instance.getAllAccounts();
        // add your code for handling multiple accounts here
        this.authService.instance.setActiveAccount(accounts[0]);
      }
    }

    login() {
      if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
        if (this.msalGuardConfig.authRequest) {
          this.authService.loginPopup({
            ...this.msalGuardConfig.authRequest,
          } as PopupRequest)
            .subscribe((response: AuthenticationResult) => {
              this.authService.instance.setActiveAccount(response.account);
              console.log(response);

            });
        } else {
          this.authService.loginPopup()
            .subscribe((response: AuthenticationResult) => {
              this.authService.instance.setActiveAccount(response.account);
              console.log(response);
            });
        }
      } else {
        if (this.msalGuardConfig.authRequest) {
          this.authService.loginRedirect({
            ...this.msalGuardConfig.authRequest,
          } as RedirectRequest);
        } else {
          this.authService.loginRedirect();
        }
      }
    }

    logout() {

      if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
        this.authService.logoutPopup({
          account: this.authService.instance.getActiveAccount(),
        });
      } else {
        this.authService.logoutRedirect({
          account: this.authService.instance.getActiveAccount(),
        });
      }
    }

    // unsubscribe to events when component is destroyed
    ngOnDestroy(): void {
      this._destroying$.next(undefined);
      this._destroying$.complete();
    }
  }

// function MSALInterceptorConfigFactory(): MsalInterceptorConfig {
//   const protectedResourceMap = new Map<string, Array<string>>();
//   protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

//   return {
//     interactionType: InteractionType.Redirect,
//     protectedResourceMap,
//   };
// }
